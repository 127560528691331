body {
  margin: 0;
  padding: 0;
  background-color: #eee;
}

a {
  text-decoration: none;
}

th {
  white-space: nowrap;
}